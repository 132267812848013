<template>
  <div id="resourceList">
    <Back title="房源列表"></Back>
    <!-- 搜索 -->
    <van-search
      v-model="params.resourceName"
      placeholder="请输入房源搜索"
      show-action
    >
      <template #action>
        <div @click="onClickButton">确认搜索</div>
      </template>
    </van-search>
    <!-- 楼幢 -->
    <van-dropdown-menu class="van-hairline--bottom">
      <van-dropdown-item
        v-model="params.projectId"
        :options="progectList"
        @change="onProjectId"
      />
      <van-dropdown-item
        v-model="params.unitId"
        :options="unitLists"
        @change="onUnitId"
      />
    </van-dropdown-menu>
    <!-- 滑块 -->
    <main>
      <van-tabs
        border
        swipeable
        color="#4F94F3"
        v-model:active="active"
        @change="onResourceType(active)"
      >
        <van-tab
          v-for="item in resourceTypeOpt"
          :title="item.typeName"
          :key="item.typeVal"
        >
          <!-- 列表 -->
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <van-cell
              v-for="i in list"
              :key="i.resourceId"
              is-link
              @click="linkTo(i.resourceId)"
            >
              <template #title>
                <span class="custom-title">
                  <template v-if="i.resourceStatus == 1">
                    <span class="custom-sign" v-if="i.approveStatus == 1"
                      >审</span
                    >
                    <span class="custom-empty" v-else>空</span>
                  </template>
                  <template v-else>
                    <span class="custom-already">租</span>
                  </template>
                  {{ i.unitName }}-{{ i.floorName }}-{{ i.resourceName }}</span
                >
              </template>
              <span>{{ i.area }}㎡&nbsp;|&nbsp;</span>
              <van-tag round type="warning">￥{{ i.unitPrice }}</van-tag>
            </van-cell>
          </van-list>
        </van-tab>
      </van-tabs>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getResourceList } from "@/api/resource/resource";
import { getUserInfo } from "@/api/mine/mine";
export default {
  components: { Back },
  data() {
    return {
      params: {
        pageNo: 0,
        pageSize: 20,
        resourceStatus: 1, //房源状态1.空置 2.已租
        projectId: "", //园区id默认全部
        unitId: "", //楼幢id默认全部
        resourceName: "", //房源号关键字搜索
      },
      active: 0, //当前激活标签对应的索引值
      resourceTypeOpt: [
        {
          typeName: "空置",
          typeVal: 1,
        },
        {
          typeName: "已租",
          typeVal: 2,
        },
      ],
      list: [], //房源列表
      loading: false, //开关loading
      finished: false, //是否已加载完成
      progectList: [],
      unitList: [],
    };
  },
  computed: {
    unitLists() {
      if (this.params.projectId) {
        let unitList = this.unitList.filter(
          (i) => i.projectId == this.params.projectId
        );
        return [
          {
            text: "全部楼幢",
            value: "",
          },
          ...unitList,
        ];
      } else {
        return this.unitList;
      }
    },
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 【请求】用户信息
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.data) {
          if (res.data.selectedProjectIdSet[0]) {
            this.params.projectId = res.data.selectedProjectIdSet[0];
          }
          this.params.projectId = res.data.selectedProjectIdSet[0];
          if (res.data.empProjectMap[1]) {
            let progectList = res.data.empProjectMap[1].map((i) => {
              return {
                text: i.projectName,
                value: i.projectId,
              };
            });
            this.progectList = [
              {
                text: "全部园区",
                value: "",
              },
              ...progectList,
            ];
          } else {
            this.progectList = [
              {
                text: "全部园区",
                value: "",
              },
            ];
          }
          if (res.data.empUnitMap[1]) {
            let unitList = res.data.empUnitMap[1].map((i) => {
              return {
                text: i.unitName,
                value: i.unitId,
                projectId: i.projectId,
              };
            });
            this.unitList = [
              {
                text: "全部楼幢",
                value: "",
              },
              ...unitList,
            ];
          } else {
            this.unitList = [
              {
                text: "全部楼幢",
                value: "",
              },
            ];
          }
          this.onLoad()
        }
      });
    },

    // 【请求】查询房源
    getResourceList() {
      let data = this.params;
      getResourceList(data).then((res) => {
        if (res.data) {
          this.list = [...this.list, ...res.data.content];
          this.loading = false; // 本次加载结束
          if (this.list.length >= res.data.total) {
            this.finished = true; //加载完了
          }
        } else {
          this.loading = false; // 本次加载结束
          this.finished = true; //加载完了
        }
      });
    },
    reset() {
      this.list = [];
      this.params.pageNo = 1;
      this.finished = false;
      this.getResourceList();
    },
    // 【监听】园区选择
    onProjectId() {
      this.params.unitId = "";
      this.reset();
    },
    // 【监听】楼幢选择
    onUnitId() {
      this.reset();
    },
    // 【监听】空置已租切换
    onResourceType(val) {
      this.params.resourceStatus = val + 1;
      this.reset();
    },
    // 【监听】房源搜索
    onClickButton() {
      this.reset();
    },
    // 【监听】下滑加载
    onLoad() {
      this.params.pageNo++;
      this.getResourceList();
    },
    // 【监听】列表跳转房源详情
    linkTo(resourceId) {
      this.$router.push({
        path: "/resource/resourceInfo",
        query: { resourceId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#resourceList {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}

.custom-sign {
  font-size: 0.12rem;
  color: $warning-color;
  display: inline-flex;
  width: 0.2rem;
  height: 0.2rem;
  border: solid 1px $warning-color;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.custom-empty {
  font-size: 0.12rem;
  color: $theme-color;
  display: inline-flex;
  width: 0.2rem;
  height: 0.2rem;
  border: solid 1px $theme-color;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.custom-already {
  font-size: 0.12rem;
  color: $success-color;
  display: inline-flex;
  width: 0.2rem;
  height: 0.2rem;
  border: solid 1px $success-color;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.van-tag--warning {
  color: rgba(232, 119, 90, 1);
  background: rgba(232, 119, 90, 0.1);
}
</style>